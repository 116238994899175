body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-tap-highlight-color: transparent;
}

textarea:focus, input:focus{
    outline: none;
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.help-btn {
  height: 14px !important;
  line-height: 10px !important;
  margin: -4px 0 0 8px !important;
  padding: 0 !important;
}

.circle {
  display: inline-flex;
  width: 10px;
  height: 10px;
  background-color: black;
  border-radius: 50%;
  margin-left: 15px;
}

.circle-green {
  background-color: green;
}

.circle-red {
  background-color: red;
}
